/* Text-length */
.max-w-20em { /* h2 824px */
    max-width: 19.62em;
}

.max-w-36em { /* h4 824px */
    max-width: 36em;
}

.max-w-40em { /* h4 929px 51.6em */
    max-width: 40.39em;
}

.max-w-46em { /* body 824px */
    max-width: 45.78em;
}

.max-w-49em { /* body 886px */
    max-width: 49.2225em;
}

.min-h-full {
    min-height: 100%;
}

/* text alignment */
.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

/* Max-width */
.mx-1033px { /* 83% of 1244px */
    max-width: 1033px;
}

.mx-1244px {
    max-width: 1244px;
}

.mx-613px {
    max-width: 613px;
}

.mx-584px {
    max-width: 584px;
}

.mx-562px {
    max-width: 562px;
}

.mx-460px {
    max-width: 460px;
}

.mx-315px {
    max-width: 315px;
}

.mx-299px {
    max-width: 299px;
}

@media (max-width: 768px) {
    .mx-width-100-sm-down {
        max-width: 100%;
    }
}

/* embed Responsive */
.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}

.embed-responsive-16by9::before {
    padding-top: 56.25%;
}

.embed-responsive::before {
    display: block;
    content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

video {
    object-fit: cover;
}
