/**
 * ╒═══════════════════════════════════════╕
 * │           HoW tO wrItE CoDE           │
 * ╞═══════════════════╤═══════════════════╡
 * │          variable │ --like-this       │
 * │ cust. media query │ --[for|when]-[..] │
 * ├───────────────────┼───────────────────┤
 * │      layout, obj. │ .o-[..]           │
 * │    element, cmpt. │ .like-this        │
 * │         CSS state │ .[is|has]-[..]    │
 * │          JS state │ .js-[..]          │
 * │       theme, mod. │ .-like-this       │
 * └───────────────────┴───────────────────┘
 */

/* --- Bootstrap --- */

@import '../../variables.css';
@import '../styles-critical/base.css';
@import '../styles-critical/font-styles.css';
@import '../styles-critical/flexbox.css';
@import '../styles-critical/layout.css';
@import '../styles-critical/utilities.css';
@import '../styles-critical/page-header.css';
@import '../styles-critical/v-animate.css';
@import '../styles-critical/hero.css';

