.hero-container,
.hero-picture,
.hero-picture-fullwidth,
.hero-picture-article {
	position: relative;
}

.hero-picture img,
.hero-picture-fullwidth img,
.hero-picture-article img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}

@media (max-width: 449px) {
	.hero-picture {
		padding-top: calc(230 / 342 * 100%);
	}

	.hero-picture-fullwidth {
		padding-top: calc(543 / 390 * 100%);
	}

	.hero-picture-article {
		padding-top: calc(230 / 342 * 100%);
	}
}

@media (min-width: 450px) {
	.hero-picture {
		padding-top: calc(621 / 1244 * 100%);
	}

	.hero-picture-fullwidth {
		padding-top: calc(840 / 1920 * 100%);
	}

	.hero-picture-article {
		padding-top: calc(528 / 1033 * 100%);
	}
}

.hero-title + div {
	margin-top: 7px;
}

@media (max-width: 768px) {
	.hero-title + div {
		margin-top: 0;
	}
}

.cta-bullet

.cta-bullet {
	position: relative;
	font-size: calc(150px + (250 - 150) * (100vw - 768px) / (1460 - 768));
	padding-bottom: 0.5em;
}

@media (max-width: 768px) {
	.cta-bullet {
		font-size: 150px;
	}
}

@media (min-width: 1461px) {
	.cta-bullet {
		font-size: 250px;
	}
}

[class*="cta-bullet-wrapper"] {
	position: absolute;
	padding: 0;
	bottom: 0;
	text-align: center;
	color: var(--weiss);
	transform: rotate(-15deg);
	font-size: inherit;
}

[class*="cta-bullet-wrapper"] .inner {
	padding: 0.062em;
	position: relative;
	width: 1em;
	height: 1em;
	display: flex;
	justify-content: center;
	align-items: center;
}

[class*="cta-bullet-wrapper"] .bullet-cta {
	z-index: -1;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 0.5em;
	transition: transform var(--default-timing);
}

[class*="cta-bullet-wrapper"] h3 {
	display: block;
	line-height: 1em;
	position: relative;
	color: var(--weiss);
	font-size: 0.124em;
}

@media (min-device-width: 1025px) {
	[class*="cta-bullet-wrapper"]:hover .bullet-cta {
		transform: scale(1.1);
		transition: transform var(--default-timing);
	}
}

@media (min-width: 768px) {
	[class*="cta-bullet-wrapper"] {
		right: 0.44em;
	}
}

@media (max-width: 767px) {
	[class*="cta-bullet-wrapper"] {
		right: 0.1333em;
	}
}


.hero-cta--red .bullet-cta {
	background-color: var(--rot);
}

.hero-cta--green .bullet-cta {
	background-color: var(--gruen);
}

.hero-cta--blue .bullet-cta {
	background-color: var(--blau);
}

.hero-cta--black .bullet-cta {
	background-color: var(--schwarz);
}

.hero-cta--yellow .bullet-cta {
	background-color: var(--gelb);
}