:root {
    /* Header */
    --header-content-height: 40px;
    --header-padding-top: 45px;
    --header-padding-bottom: 40px;
    --header-sticky-padding-top: 25px;
    --header-sticky-padding-bottom: 25px;
    --header-padding-top-sm: 25px;
    --header-padding-bottom-sm: 25px;
    --header-sticky-padding-top-sm: 15px;
    --header-sticky-padding-bottom-sm: 15px;

    /* Burger */
    --burger-placeholder-width: 38px;
    --burger-placeholder-height: 24px;
    --burger-width: 39px;
    --burger-height: 24px;
    --burger-diagonal: 31px; /* sqrt(--burger-width^2 + --burger-height^2) */
    --burger-bar-height: 2px;
    --burger-background: transparent;
    --burger-color-closed: inherit;

    --ios-menubar-height: 5rem;

    --flyout-bgcolor: var(--grau4);

    --default-navigation-timing: 0.3s;

    --level-0-spacing: 42px;
    /*--level-0-size: 22px; */

    /** New **/
    --search-margin: 40px;
}

@media (max-width: 1344px) {
    :root {
        --level-0-spacing: 22px;
    }
}

.page-header {
    position: sticky;
    width: 100%;
    top: -1px;
    z-index: 10;
    background-color: var(--page-background);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0);
}


@media (min-width: 993px) {
    .page-header.js-sticky {
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
        transition: transform 0.25s ease 0.25s, box-shadow 0.25s ease 0.15s;
    }

    .page-header.js-sticky:not(.js-open) {
        /* transform: translateY(-100%); */
    }

    .page-header.js-sticky.js-visible {
        transform: translateY(0);
        transition: transform 0.25s ease 0s, box-shadow 0s ease 0.15s;
    }
}

@media (max-width: 992px) {
    .page-header.js-sticky.js-open {
        position: fixed;
        z-index: 999;
    }

    .page-header.js-sticky {
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    }
}

/* Logo */
.page-logo {
    position: relative;
    display: inline-flex;
    height: 40px;
}

.page-logo img {
    transition: transform 0.15s ease 0s;
}

.page-header.js-sticky .page-logo img {
    transform: scale(0.8) translateY(0px);
}

@media (min-width: 993px) {
    .page-logo img {
        transform-origin: top left;
        min-width: 91px;
        min-height: 42px;
        max-height: 80px;
        max-width: none;
        position: absolute;
        top: 50%;
        margin-top: -12px;
        transform: scale(1) translateY(-50%);
    }

    .page-header.js-sticky .page-logo img {
        margin-top: 0;
        transform: scale(0.8) translateY(-50%);
    }

    #overlay {
        display: none;
    }
}

@media (max-width: 992px) {
    .page-logo img {
        transform-origin: center left;
        min-height: 37px;
        max-height: 37px;
        max-width: none;
    }

    #overlay {
        display: none;
    }
}

.page-nav {
    position: static!important;
    z-index: 1000 !important;
}

@media (max-width: 992px) {
    .page-nav {
        position: relative!important;
        z-index: 1000 !important;
        background: white;
    }
}

.o-navigation-wrapper {
    margin-right: -8px;
}

@media (min-width: 993px) {
    .page-meta {
        min-height: 85px;
        z-index: 10;
        background-color: var(--page-background);
    }
}

/* flyout */

@media (max-width: 992px) {
    .flyout {
        position: fixed;
        top: -100vh;
        bottom: auto;
        left: 0;
        width: 100%;
        max-width: 100vw;
        overflow: hidden;
        height: 100vh;
    }

    .flyout.js-animate {
        transition: top 0.25s linear 0.25s;
    }

    .flyout.js-open {
        transition: top 0.25s linear 0s;
        z-index: 999;
        top: 0;
    }
}

.flyout-scrollbox {
    position: absolute;
    top: calc( var(--header-padding-top-sm) + var(--header-padding-bottom-sm) + var(--header-content-height) );
    height: calc( 100vh - var(--header-padding-top-sm) - var(--header-content-height) - var(--header-padding-bottom-sm) );
    left: 0;
    width : 100%;
    overflow-y: scroll;
    opacity: 0;
    transition: opacity 0.25s 0s, top 0.25s 0s, visibility 0s 0s;
    -webkit-overflow-scrolling: touch;
    visibility: hidden;
}

.flyout.js-open > .flyout-scrollbox {
    display: none;
    top: calc( var(--header-padding-top-sm) + var(--header-padding-bottom-sm) + var(--header-content-height) );
    opacity: 1;
    transition: opacity 0.25s 0.3s, top 0.25s 0.3s, visibility 0s 0.35s;
    visibility: visible;
}

.flyout-inner {
    min-height: calc( 100vh - var(--header-padding-top-sm) - var(--header-content-height) - var(--header-padding-bottom-sm) );
    margin-top: 0!important;
    margin-bottom: 0!important;
    background: var(--flyout-bgcolor);
}

.flyout-content {
    min-height: calc( 100vh - var(--header-padding-top-sm) - var(--header-content-height) - var(--header-padding-bottom-sm) - var(--section-margin-height) - 70px - 70px );
}

@media (max-width: 992px) {

    .flyout.js-open .flyout-scrollbox {
        display: block;
        margin-top: 1px;
        top: calc( var(--header-padding-top-sm) + var(--header-padding-bottom-sm) + var(--header-content-height) );
        height: calc( 100vh - var(--header-padding-top-sm) - var(--header-content-height) - var(--header-padding-bottom-sm) );
    }

    .flyout-scrollbox {
        top: 0;
        height: calc( 100vh - var(--header-padding-top-sm) - var(--header-content-height) - var(--header-padding-bottom-sm) );
    }

    .page-header.js-sticky .flyout.js-open .flyout-scrollbox {
        top: calc( var(--header-sticky-padding-top-sm) + var(--header-sticky-padding-bottom-sm) + var(--header-content-height) );
        height: calc( 100vh - var(--header-sticky-padding-top-sm) - var(--header-content-height) - var(--header-padding-bottom-sm) );
    }

    .page-header.js-sticky .flyout-scrollbox {
        height: calc( 100vh - var(--header-sticky-padding-top-sm) - var(--header-content-height) - var(--header-sticky-padding-bottom-sm) );
    }

    .flyout-inner {
        min-height: calc( 100vh - var(--header-padding-top-sm) - var(--header-content-height) - var(--header-padding-bottom-sm) );
    }

    .flyout-content {
        min-height: calc( 100vh - var(--header-padding-top-sm) - var(--header-padding-bottom-sm) - var(--header-content-height) - var(--section-margin-height) - 15vw - 15vw );
    }
}

/* burger */
.burger {
    height: var(--burger-placeholder-height);
    background: var(--burger-background);
    position: relative;
    right: 0;
    color: var(--burger-color-closed);
    z-index: 3;
    outline: 0;
    padding-left: calc( (var(--burger-placeholder-width) - var(--burger-width)) / 2 );
    padding-right: calc( (var(--burger-placeholder-width) - var(--burger-width)) / 2 );
    margin: 0 auto;
    box-shadow: none;
    border: 0;
}

.burger-bars {
    position: relative;
    height: var(--burger-height);
    width: var(--burger-width);
    margin: 2px 2px 0 22px;
}

.js-open .burger-bars {
    width: 23px;
}

.burger-bar {
    display: block;
    position: absolute;
    width: var(--burger-width);
    height: var(--burger-bar-height);
    background: currentColor;
    transition: opacity var(--default-navigation-timing), transform var(--default-navigation-timing), width var(--default-navigation-timing);
    will-change: opacity, transform, width;
    right: 0;
}

.burger-bar-top { top: 0; transform-origin: top left; }
.burger-bar-middle {
    top: 50%;
    transition: transform var(--default-navigation-timing);
    transform-origin: center;
    transform: scaleX(1) translateY(-50%);
}
.burger-bar-bottom { bottom: 0; transform-origin: bottom left; }

.burger.js-open .burger-bar-top {
    top: 0;
    transform: rotate(45deg) translateY(-50%);
    width: var(--burger-diagonal);
    right: -8px;
}
.burger.js-open .burger-bar-middle { transform: scaleX(0) translateY(-50%); }
.burger.js-open .burger-bar-bottom {
    bottom: 2px;
    transform: rotate(-45deg) translateY(50%);
    width: var(--burger-diagonal);
    right: -8px;
}

.burger-label {
    font-size: 16px;
    position: relative;
    left: 1px;
    top: -2px;
    font-weight: 500;
}

.burger:not(.js-open) .burger-label-close,
.burger.js-open .burger-label-open {
    display: none;
}

@media (min-width: 993px) {
    #mainNavigation .level-0 > ul ul {
        display: block;
    }

    #flyoutScrollbox .nav-link.mobile-active-item {
        background-color: rgba(0,0,0,0.05);
        border-radius: 8px;
        padding-left: 8px;
        margin-left: -8px;
        padding-right: 12px;
    }
}

/*@media (max-width: 992px) {*/
/*    .primary-nav {*/
/*        border-bottom: 1px solid var(--hover-color);*/
/*    }*/

/*    .js-open > .primary-nav {*/
/*        border: 0;*/
/*    }*/
/*}*/

/** Flyout **/
#flyoutScrollbox .nav-link {
    margin-left: 0;
    display: block;
    padding: 16px 11px 18px 0;
    border-radius: 0;
    border-bottom: 1px solid var(--beige);
}

#flyoutScrollbox .level-0 > .nav-link {
    font-size: 22px;
}

#flyoutScrollbox .nav-link.is-active {
    margin-left: 0;
    background-color: var(--hover-color);
    border-radius: 8px;
}

.flyout-nav {
    padding-bottom: 135px;
}

.flyout-nav .secondary-menu {
    margin-top: 64px;
}

/** Search **/
#search-flyout {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.25s linear 0.25s, visibility 0s linear 0.5s;
}

#search-flyout.js-visible {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.25s linear 0.05s, visibility 0s linear 0s;
}

#search-backdrop {
    background: var(--backdrop-color);
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    width: 100%;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.25s, z-index 0.5s;
}

#search-backdrop.js-visible {
    z-index: 5;
    opacity: .5;
    transition: opacity 0.25s 0.25s, z-index 0.5s;
}

.search-panel {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 10;
    padding-top: 26px;
    max-width: 100vw;
    background-color: var(--weiss);
}

/*.search-panel::before {*/
/*    content: "";*/
/*    position: absolute;*/
/*    top: 0;*/
/*    height: 100%;*/
/*    background-color: inherit;*/
/*    left: -5000px;*/
/*    right: -5000px;*/
/*}*/

.search-result-counter {
    line-height: 1em;
}

#search-input {
    color: var(--schwarz);
    outline: none;
    border: none;
    width: 100%;
    font-size: 42px;
    padding: 0 2px 20px;
    border-bottom: 1px solid var(--off-white);
    margin-right: 0;
}

#search-input:focus {
    box-shadow: none !important;
    background-color: transparent;
}

#search-input::-webkit-input-placeholder { /* Edge */
    color: #757575;
}

#search-input::placeholder {
    color: #757575;
}

.input-group-addon {
    position: absolute;
    right: -8px;
    bottom: 38px;
    display: block;
}

.search-icon::after {
    content: "\e646";
    color: var(--grau1);
}

.flyout-nav .search-input {
    border-radius: 8px;
    height: 56px;
    padding: 10px 12px 10px 50px;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    margin-right: 0;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
}

.search-input::placeholder {
    color: var(--grau1);
    opacity: 1;
}

.flyout-nav .search-input:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.32rem rgb(0 123 255 / 25%) !important;
}

.flyout-nav .search-icon::after {
    display: none;
}

.search-icon::before {
    display: none;
}

@media (min-width: 576px) {
    .search-input {
        margin-right: 8px;
    }
}

/** Secondary **/
#secondaryNavigation {
    margin-right: -4px;
}
#secondaryNavigation .nav-link {
    display: block;
    padding: 10px 12px;
    margin-left: 25px;
}

#secondaryNavigation .nav-link:not(.search) {
    background-color: var(--blue);
}

#secondaryNavigation .search::after {
    margin-top: -4px;
    display: inline-block;
    font-size: 14px;
}

#secondaryNavigation .search {
    cursor: pointer;
}

/** Main Navigation **/
#mainNavigation .level-0 > a.link {
    padding: 10px 14px 12px;
    margin-left: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.level-0 > a.link:not([href^="tel"]):not([href^="phone"]):not(.search)::after {
    display: none;
}

#mainNavigation .level-0 ul {
    display: none;
}

.nav-items-container {
    display: flex;
    padding-top: var(--header-padding-top);
    padding-bottom: var(--header-padding-bottom);
    align-items: flex-end;
    transition: padding 0.15s ease 0s;
}

.nav-items-container > * {
    max-height: var(--header-content-height)
}

.nav-items-container {
    padding-top: var(--header-sticky-padding-top);
    padding-bottom: var(--header-sticky-padding-bottom);
}

@media (max-width: 992px) {
    .nav-items-container {
        padding-top: var(--header-padding-top-sm);
        padding-bottom: var(--header-padding-bottom-sm);
        align-items: center;
    }

    .page-header.js-sticky .nav-items-container {
        padding-top: var(--header-sticky-padding-top-sm);
        padding-bottom: var(--header-sticky-padding-top-sm);
    }
}

#mainNavigation .is-active > ul,
#mainNavigation .is-active + .splitted-level > ul {
    display: block;
}

/** New Submenu **/
.level-0 > .submenu {
    background: var(--flyout-bgcolor);
    width: 100%;
    display: none;
    margin: 0;
    padding: 0;
}

.link.nav-link {
    margin-top: 0;
    margin-bottom: 0;
    border-radius: .5em;
}

#mainNavigation .is-active > ul {
    display: block;
}

.submenu .nav-link {
    position: relative;
    padding: 10px 41px 10px 12px;
    width: 100%;
}

.has-submenu > a.link::after,
.submenu a.link:not([href^="tel"]):not([href^="phone"])::after {
    display: inline;
    content: "\e628";
    margin-left: 25px;
    position: absolute;
    right: 13px;
    margin-top: 2px;
    font-size: 15px;
}

.empty-nav-item {
    padding-top: 44px;
}

.submenu a.link.has-no-link:not([href^="tel"]):not([href^="phone"])::after {
    display: none;
}

.submenu a.link[target="_blank"]:not([href^="tel"]):not([href^="phone"])::after {
    content: "\e65a" !important;
    transform: none;
}

/** Search **/
.nav-link.search::after {
    content: "\e610";
    display: inline-block;
    vertical-align: middle;
    margin-left: 12px;
    font-size: inherit;
}

.search-icon-container {
    position: absolute;
    left: 20px;
    top: 17px;
}

.search-form {
    margin: 0 auto;
    max-width: var(--page-maxwidth);
}

@media (min-width: 993px) {
    #mainNavigation {
        margin-right: 7px;
    }
    /** Main Navigation **/
    .o-navigation {
        min-height: 40px;
    }

    #mainNavigation .js-active > ul {
        display: flex;
        min-height: 250px;
    }

    /** Submenu **/
    .level-0 > .submenu {
        position: absolute;
        left: 0;
        top: 100%;
        padding: 33px calc(var(--page-gutter-width) - var(--nav-submenu-spacing) / 2) 40px;
        display: none;
        flex-wrap: wrap;
        justify-content: start;
        flex-direction: row;
        align-items: start;
        width: calc(100% + var(--page-gutter-width) * 2);
        margin-left: calc(var(--page-gutter-width) * -1);
    }

    .level-0 > .submenu.nav-three-columns {
        display: block;
        -webkit-column-gap: 0;
        -moz-column-gap: 0;
        column-gap: 0;
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
    }

    a.is-level-0.is-open {
        background-color: rgba(0,0,0,0.05)
    }

    .submenu.nav-three-columns > .level-1 {
        margin-bottom: 50px;
    }

    .submenu .nav-link {
        margin-left: 0;
    }

    .submenu .level-2 {
        margin-top: 14px;
    }


    /** Submenu Three Columns **/
    #mainNavigation .js-active > ul.nav-three-columns {
        display: block;
    }

    .nav-three-columns .level-1 {
        break-inside: avoid-column;
        margin-right: 0;
    }

}

@media (max-width: 992px) {
    .header-spacing {
        margin-top: 40px !important;
        margin-bottom: 67px !important;
    }

    /** Submenu **/
    .level-0 > .submenu {
        padding: 13px 0 14px 1px;
        font-size: 18px;
        border-bottom: 1px solid var(--beige);
    }

    .level-0 > .nav-link {
        min-height: 56px;
    }

    #flyoutScrollbox .nav-link.is-active,
    #flyoutScrollbox .nav-link:hover {
        background-color: transparent;
        border-radius: 0;
    }

    .level-1:not(:last-child) {
        margin-bottom: 6px;
    }

    .level-1:not(.js-active) .submenu {
        display: none;
    }

    #flyoutScrollbox .o-navigation {
        margin-top: 4px;
    }

    .js-active > .submenu {
        display: block;
    }

    .has-submenu > a.link:not(.js-active)::after {
        content: "..."!important;
    }

    .level-2:not(:last-child) {
        margin-bottom: 6px;
    }

    .level-1 .submenu {
        padding-top: 2px;
        padding-left: 2px;
    }

    #flyoutScrollbox .submenu .nav-link {
        padding: 10px 35px 10px 0;
        border: 0;
    }

    .submenu a.link:not([href^="tel"]):not([href^="phone"])::after {
        display: flex;
        height: 100%;
        top: 0;
        right: 12px;
    }

    a.is-open, .is-active {
        font-weight: bold;
    }

    .submenu a.is-open:not(.js-active)::before, .submenu a.is-active::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: -10px;
        right: -10px;
        background-color: rgba(0,0,0,0.05);
        border-radius: 8px;
    }

    .level-1  a.link:not([href^="tel"]):not([href^="phone"])::after {
        top: -2px;
        right: 0;
    }

    /** Search **/
    .search-icon::before {
        content: "\e610";
        display: inline-block;
        vertical-align: top;
        font-size: 16px;
        color: var(--grau1);
        font-style: normal;
        margin-top: 2px;
        margin-left: -2px;
    }

    .search-form {
        margin: var(--search-margin) 0 calc(var(--search-margin) - 20px);
    }
}
