body {
    font-family: var(--default-font-family);
    font-size: var(--default-font-size);
    line-height: var(--default-line-height);
    letter-spacing: 0.001em;
}

h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
    font-feature-settings: 'dlig' 1, 'liga' 1, 'lnum' 1, 'kern' 1;
    font-family: var(--headings-font-family);
    color: var(--headings-color);
    font-weight: var(--headings-font-weight);
    line-height: var(--headings-line-height);
    text-rendering: geometricPrecision;
}

h1, .h1 { font-size: var(--h1-font-size); line-height: var(--h1-line-height); letter-spacing: 0.15px; }
h2, .h2 { font-size: var(--h2-font-size); line-height: var(--h2-line-height); }
h3, .h3 { font-size: var(--h3-font-size); line-height: var(--h3-line-height); }
h4, .h4 { font-size: var(--h4-font-size); line-height: var(--h4-line-height); }
h5, .h5 { font-size: var(--h5-font-size); line-height: var(--h5-line-height); }
figcaption, .caption { font-size: var(--caption-font-size); line-height: var(--caption-line-height); }
.big1, .big2 { font-size: var(--biggest-font-size); line-height: var(--biggest-line-height); }
.big3, .big4 { font-size: var(--big-font-size); line-height: var(--big-line-height); }

.big1, .big3 { font-weight: var(--headings-font-weight) }
.big2, .big4 { font-weight: var(--medium-font-weight) }

p ~ h1, p ~ .h1 { margin-bottom: var(--h1-margin-bottom); }
p ~ h2, p ~ .h2 { margin-bottom: var(--h2-margin-bottom); }
p ~ h3, p ~ .h3 { margin-bottom: var(--headings-margin-bottom); }
p ~ h4, p ~ .h4 { margin-bottom: var(--headings-margin-bottom); }
p ~ h5, p ~ .h5 { margin-bottom: var(--headings-margin-bottom); }

.prose-h3-to-h4 h3 {
    font-size: var(--h4-font-size);
    margin-bottom: var(--subtitle-spacing);
}

.prose-spaced-md-up h3:first-child,
.prose-spaced-md-up h4:first-child,
.prose-spaced-md-up h5:first-child  {
    margin-top: var(--p-margin-bottom);
}

.prose-h3-to-h4 h3 + p {
    margin-top: var(--subtitle-spacing);
}

h3 + p,
h4 + p,
h5 + p {
    margin-top: var(--p-margin-bottom);
}

p + h3,
p + h4,
p + h5 {
    padding-top: var(--p-margin-bottom);
}

ul:not(:last-child),
p:not(:last-child) {
    margin-bottom: var(--p-margin-bottom);
}

ul {
    font-weight: 500;
}

@media (min-width: 768px) {
    .prose.list-2cols-md-up ul {
        columns: 2;
        column-gap: 36px;
    }
}

button, .button {
    font-size: var(--button-font-size);
}

input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]),
textarea[name], .selection-input, .selection-list, .file-upload-text {
    font-size: var(--input-font-size);
}

p, .link {
    letter-spacing: 0.015em;
}

.font-small {
    font-size: 16px;
}

/* font-weights */
.font-normal {
    font-weight: 400
}

.font-medium {
    font-weight: 500
}

.font-semibold {
    font-weight: 600
}

/* utilities */
.font-themify::after,
.font-themify::before {
    font-family: themify;
}

.hyphenate {
    hyphens: auto;
    -webkit-hyphens: auto;
    overflow-wrap: break-word;
}

.no-hyphenate {
    hyphens: none;
    overflow-wrap: break-word;
}

@supports not ((-webkit-hyphens: auto) or (-ms-hyphens: auto) or (hyphens: auto)) {
    .hyphenate {
        hyphens: manual;
        -webkit-hyphens: manual;
        word-break: break-word;
    }
}

@media (max-width: 767px) {
    h1, .h1 {
        hyphens: auto;
        -webkit-hyphens: auto;
        overflow-wrap: break-word;
    }
}
