:root {
    --dunkelblau: #005578;
    --blau: #008cc8;
    --hellblau: #bedceb;
    --dunkelgruen: #006450;
    --gruen: #46aa6e;
    --hellgruen: #c3e6aa;
    --dunkelgelb: #d79b00;
    --gelb: #ffcb46;
    --hellgelb: #fae69b;
    --dunkelrot: #b93c37;
    --rot: #f55f4b;
    --hellrot: #ffcdc3;
    --dunkelgrau: #5A504B;
    --grau: #afa596;
    --hellgrau: #e1dcd2;
    --weiss: #ffffff;
    --schwarz: #000000;
    --grau1: #979797;
    --grau2: #d2d2d2;
    --grau3: #e6e6e6;
    --grau4: #eef0f5;

    --hover-color: #e2e4e4;
    --off-white: #E1E3E3;
    --ice-blue: #eef0f0; /* Später entfernen */
    --beige: #cdcbc7;
    --blue: #BEDCEB;

    --page-background: white;
    --backdrop-color: var(--schwarz);

    /* Text - Default */
    --default-font-family: "Matter", serif;
    --default-dark-text-color: var(--schwarz);
    --default-light-text-color: var(--weiss);
    --default-font-size: 18px;
    --default-font-weight: 400;
    --medium-font-weight: 500;
    --default-line-height: 1.33;

    /* Text - Headings */
    --headings-font-family: var(--default-font-family);
    --headings-color: inherit;
    --headings-font-weight: 500;
    --headings-line-height: 1.17;
    --headings-margin-bottom: 24px;

    --h1-margin-top: 0;
    --h1-margin-bottom: 67px;
    --h2-margin-top: 0;
    --h2-margin-bottom: 67px;
    --h3-margin-top: 0;
    --h3-margin-bottom: 40px;

    --biggest-font-size: 100px;
    --big-font-size: 72px;
    --h1-font-size: 42px;
    --h2-font-size: 42px;
    --h3-font-size: 31px;
    --h4-font-size: 23px;
    --h5-font-size: 18px;
    --caption-font-size: 16px;
    --button-font-size: 18px;
    --input-font-size: 16px;

    --biggest-line-height: 110px;
    --big-line-height: 85px;
    --h1-line-height: 55px;
    --h2-line-height: 55px;
    --h3-line-height: 40px;
    --h4-line-height: 32px;
    --h5-line-height: 24px;
    --caption-line-height: 24px;
    --button-line-height: 18px;
    --input-line-height: 16px;

    --p-margin-bottom: 24px;
    --media-margin-height: 2em;

    /* Page - default, layout */
    --page-maxwidth: 1244px;
    --page-maxwidth-large: 1920px;
    --page-gutter-width: 98px; /*!* 1244 + 2 x 98 = 1440 *!*/

    /* Spacings Root Variables */
    --section-spacing: 240px;
    --component-spacing: 120px;
    --component-spacing-2: 180px;
    --header-spacing: 80px;
    --block-spacing: 67px;
    --article-spacing: 55px;
    --element-spacing: 40px;
    --quote-spacing: 32px;
    --big-text-spacing: 30px;
    --text-spacing: 24px;
    --small-spacing: 24px;
    --tiny-spacing: 16px;
    --nav-spacing: 56px;
    --nav-submenu-spacing: 69px;
    --subtitle-spacing: 19px;

    --section-margin-height: var(--section-spacing);
    --section-padding-height: var(--header-spacing);
    --component-margin-height: var(--component-spacing);

    --layout-gutter: var(--element-spacing);
    --layout-small-gutter: var(--small-spacing);
    --layout-tiny-gutter: var(--tiny-spacing);
    --layout-nav-gutter: var(--nav-spacing);
    --layout-nav-submenu-gutter: var(--nav-submenu-spacing);

    --layout-component-gap: var(--component-spacing);
    --layout-gap: var(--element-spacing);
    --layout-block-gap: var(--block-spacing);
    --layout-element-gap: var(--element-spacing);
    --layout-small-gap: var(--small-spacing);
    --layout-tiny-gap: var(--tiny-spacing);

    /* Rwd */
    --rwd-margin-height-md: 21px; /* contact 1 */
    --rwd-margin-height-lg: 21px;
    --rwd-margin-height-sm: 12px;
    --menu-toggle-height: 55px;
    --menu-toggle-height-reduced: 26px;

    /* Form */
    --form-default-color: 0, 0%, 32%;
    --form-border-color: var(--dunkelgrau);
    --form-error-color: 359, 83%, 71%;
    --form-active-color: 210, 50%, 60%;
    --form-readonly-color: var(--grau);
    --form-invalid-color: 36, 100%, 50%;
    --form-error-border-color: 359, 83%, 71%;
    --form-valid-border-color: 85, 100%, 30%;
    --form-error-text-color: hsl(359, 100%, 50%);
    --form-error-background: 359, 83%, 71%;
    --form-success-color: #87fe6a;
    --form-input-background: #ffffff;
    --form-option-hover-color: #ededed;

    /* Button */
    --button-default-background: var(--schwarz);
    --button-active-background: var(--dunkelgrau);
    --button-default-color: var(--default-light-text-color);
    --button-active-color: var(--default-light-text-color);
    --button-border-color: var(--schwarz);

    /* Timings */
    --default-timing: 250ms;

    /* Default space units */
    --zero-width-space: 0;                 /*   0  em | Allows line break, like <wbr> */
    --hair-space: 0.0417em;                /* 1/24 em | Width varies, but narrower than thin space */
    --fine-space: 0.125em;                 /* 1/8  em | For French punctuation */
    --six-per-em-space: 0.1667em;          /* 1/6  em | Might be suitable for grouping */
    --thin-space: 0.2em;                   /* 1/5  em | Recommended minimum width of space */
    --medium-mathematical-space: 0.2222em; /* 4/18 em | Around operators (according to Chicago) */
    --four-per-em-space: 0.25em;           /* 1/4  em | mid space: Average width of normal space */
    --thick-space: 0.2778em;               /* 5/18 em | Around relational operators (according to Chicago) */
    --three-per-em-space: 0.3333em;        /* 1/3  em | Width of space in a wide font */
    --en-space: 0.5em;                     /* 1/2  em | Recommended maximum width of space */
    --em-space: 1em;                       /*   1  em | One */

    /* Breakpoints */
    --bp-sm: 544px;
    --bp-md: 768px;
    --bp-lg: 992px;
    --bp-xl: 1200px;
}

@media (max-width: 1344px) {
    :root {
        --page-maxwidth: 944px;
        --nav-spacing: calc(8px + (40 - 8) * (100vw - 992px) / (1344 - 992));
        --nav-submenu-spacing: 50px;
    }
}

@media (max-width: 992px) {
    :root {
        --nav-submenu-spacing: 0;
        --nav-spacing: 0;
    }
}

@media (max-width: 767px) {
    :root {
        --section-margin-height: 120px;
        /*--page-gutter-width: 30px;*/
        --component-margin-height: 120px;

        --biggest-font-size: 50px;
        --big-font-size: 50px;
        --big1-font-size: 72px;
        --big2-font-size: 50px;
        --h1-font-size: 29px;
        --h2-font-size: 29px;
        --h3-font-size: 24px;
        --h4-font-size: 21px;
        --h5-font-size: 18px;
        --caption-font-size: 14px;
        --button-font-size: 18px;
        --input-font-size: 16px;

        --biggest-line-height: 54px;
        --big-line-height: 54px;
        --big1-line-height: 80px;
        --big2-line-height: 54px;
        --h1-line-height: 38px;
        --h2-line-height: 38px;
        --h3-line-height: 28px;
        --h4-line-height: 31px;
        --h5-line-height: 24px;
        --caption-line-height: 22px;
        --button-line-height: 18px;
        --input-line-height: 16px;

        --page-maxwidth: 524px;

        --page-gutter-width: 24px;

        --subtitle-spacing: 20.5px;
    }
}
