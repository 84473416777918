/* --- Reset --- */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, button, input {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

main {
    display: block;
    overflow-x: hidden;
}

ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: "";
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

img,
svg { max-width: 100%; height: auto; }


/* --- Default --- */

/* Default settings */
*, *::before, *::after {
    box-sizing: inherit;
    -webkit-tap-highlight-color: inherit;
}

/*
  Repeating the background mostly makes sense in the <body>.
  Otherwise, people usually want the image and preferably its center
  (not the top-right corner)
*/
*:not(body) {
    background: no-repeat center center;
    background-size: cover;
}

/*
  Makes the hidden attribute works even when an element is styled display: flex
  http://lists.w3.org/Archives/Public/public-whatwg-archive/2014May/0001.html
*/
[hidden], template {
    display: none !important;
}

[draggable] { user-select: none; }

html, body {
    text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    word-wrap: normal;
    hyphens: none;
    overflow-wrap: break-word;

    /* Page */
    background: var(--page-background) fixed;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    /* Blocks */
    box-sizing: border-box;

    /* App */
    /* -webkit-touch-callout: none; */
    /* -webkit-user-select: none; */

    /* -ms-overflow-style: -ms-autohiding-scrollbar; */
}

html { font-size: 62.5%; }

p {
    hyphens: auto;
    overlap-wrap: break-word;
}

@supports not ((-webkit-hyphens: auto) or (-ms-hyphens: auto) or (hyphens: auto)) {
    p {
        hyphens: manual;
        word-break: break-word;
    }
}

a {
    text-decoration: underline;
    color: inherit;
}

.has-hidden-links a,
a.has-hidden-links {
    text-decoration: none;
}

.no-underline-links a,
a.no-underline-links {
    text-decoration: none;
}

@media (min-device-width: 1025px) {
    .no-underline-links a:hover,
    a.no-underline-links:hover {
        text-decoration: underline;
    }
}

@media (max-device-width: 1024px) {
    .no-underline-links a:active,
    a.no-underline-links:active {
        text-decoration: underline;
    }
    .underline-links-touch a,
    a.underline-links-touch,
    .no-underline-links.underline-links-touch a,
    a.no-underline-links.underline-links-touch {
        text-decoration: underline;
    }
}

button,
div[role="button"] { cursor: pointer; }

.is-dark { color: var(--default-light-text-color); }
.is-light { color: var(--default-dark-text-color); }

.has-async-css {
    opacity: 0;
    pointer-events: none;
}

picture {
    display: flex;
    width: 100%;
}

picture > img {
    display: inline-block;
    width: 100%;
}
/* --- Accessibility --- */

/* .is-visually-hidden:not(:focus):not(:active) */
.is-visually-hidden {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    margin: -1px !important;
    border: 0 !important;
    padding: 0 !important;
    white-space: nowrap !important;
    clip-path: inset(100%) !important;
    clip: rect(0 0 0 0) !important;
    overflow: hidden !important;
}

/* Skip links are shortcuts at the top of the page */
.skip-links {
    position: absolute;
    z-index: 1001;
}

.skip-links a {
    position: absolute;
    display: inline-block;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    padding: 1em;
    background: white;
    color: black;
    text-decoration: none;
    font-size: 1.6rem;
    outline: 0;
    border: 3px solid black;
}

.skip-links a:focus {
    position: static;
    width: auto;
    height: auto;
}


/* --- Layout --- */

.o-view {
    position: relative;
    width: 100%;
    min-height: 100vh;
    z-index: 3;
    overflow: hidden;
}

.o-view.is-absolute {
    position: absolute;
    top: 0;
    left: 0;
}

.page-content {
    min-height: 100vh;
}

.-editMode .page-content,
.page-content.js-mounted {
    min-height: 0;
}

.page-content form {
    visibility: hidden;
}

.-editMode form,
.page-content.js-mounted form {
    visibility: visible;
}

/* Sections and Groups */
.o-section {
    position: relative;
    clear: both;
    margin-top: var(--section-margin-height);
    margin-bottom: var(--section-margin-height);
}

.o-section.bsb-section-container {
    margin-top: var(--component-spacing);
    margin-bottom: var(--component-spacing);
}

.o-section.bsb-section-container:not(:first-child) + .o-section.bsb-section-container {
    margin-top: var(--component-spacing-2);
}

.o-section.bsb-section-cta {
    margin-top: var(--component-spacing);
    margin-bottom: 0;
}

.-editMode .o-section.bsb-section-cta {
    margin-top: 0;
    margin-bottom: 0;
}

.o-section.bsb-section-cta + .o-section.bsb-section-cta {
    margin-top: 0;
}

.-editMode .o-section {
    margin-top: calc(var(--section-margin-height) / 2);
    margin-bottom: calc(var(--section-margin-height) / 2);
}

.o-section:not(.has-no-gutter):not(.is-full-width) {
    margin-left: var(--page-gutter-width);
    margin-right: var(--page-gutter-width);
}

main > .o-section:not([class*=bg-]):first-child {
    margin-top: var(--component-spacing);
}

@media (max-width: 992px) {
    .o-section:not(.has-no-gutter).is-mobile-full-width {
        margin-left: 0;
        margin-right: 0;
    }
}

.has-top-inner-space {
    padding-top: var(--section-padding-height);
}

.pb-medium {
    padding-bottom: var(--section-padding-height);
}

.has-article-top-inner-space {
    padding-top: var(--article-spacing);
}

.has-article-bottom-inner-space {
    padding-bottom: var(--article-spacing);
}

.has-block-top-inner-space {
    padding-top: var(--block-spacing);
}

.has-block-bottom-inner-space {
    padding-bottom: var(--block-spacing);
}


@media (max-width: 768px) {
    .has-block-top-inner-space-sm-down {
        padding-top: var(--block-spacing);
    }

    .has-block-bottom-inner-space-sm-down {
        padding-bottom: var(--block-spacing);
    }
}

.o-group {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.o-group-firstchild,
.o-group-lastchild {
    display: inline;
}

.o-group:not(.is-boundless) { max-width: var(--page-maxwidth); }
.o-group.is-large:not(.is-boundless) { max-width: var(--page-maxwidth-large); }
.o-group:first-of-type { margin-top: 0; }
.o-group:last-child { margin-bottom: 0; }

/* Inner gutter */
.o-section.has-no-gutter > .o-group.has-inner-gutter,
.o-section.is-full-width > .o-group.has-inner-gutter {
    padding-right: var(--page-gutter-width);
    padding-left: var(--page-gutter-width);
}

.o-section.has-no-gutter > .o-group.has-inner-gutter:not(.is-boundless),
.o-section.is-full-width > .o-group.has-inner-gutter:not(.is-boundless) {
    max-width: calc(var(--page-maxwidth) + 2 * var(--page-gutter-width)) !important;
}

.o-section.has-no-gutter > .o-group.has-inner-gutter.is-large:not(.is-boundless),
.o-section.is-full-width > .o-group.has-inner-gutter.is-large:not(.is-boundless) {
    max-width: calc(var(--page-maxwidth-large) + 2 * var(--page-gutter-width)) !important;
}

.o-component:not(:last-child) {
    margin-bottom: var(--component-margin-height);
}

@media (max-width: 992px) {
    .o-section.is-mobile-full-width > .o-group.has-inner-gutter:not(.is-boundless) {
        max-width: calc(var(--page-maxwidth) + 2 * var(--page-gutter-width)) !important;
    }

    .o-section.is-mobile-full-width > .o-group.has-inner-gutter {
        padding-right: var(--page-gutter-width);
        padding-left: var(--page-gutter-width);
    }
}

/* Layout Modifiers */
.o-section.has-no-bottom-space { margin-bottom: 0; }
.o-section.has-no-bottom-space:not(.pb-medium) { padding-bottom: 0; }
.o-section.has-no-top-space { margin-top: 0; }
.o-section.has-no-top-space:not(.has-top-inner-space) { padding-top: 0; }

/* --- Alignement --- */

.is-left:not(.is-vertical) {
    justify-content: flex-start;
    text-align: left;
    object-position: left;
}
.is-left.is-vertical { align-items: flex-start; }

.is-center:not(.is-vertical) {
    justify-content: center;
    text-align: center;
    object-position: center;
}
.is-center.is-vertical { align-items: center; }

.is-right:not(.is-vertical) {
    justify-content: flex-end;
    text-align: right;
    object-position: right;
}
.is-right.is-vertical { align-items: flex-end; }

@media (max-width: 767px) {

    .is-left-sm-down:not(.is-vertical) {
        justify-content: flex-start;
        text-align: left;
        object-position: left;
    }
    .is-left-sm-down.is-vertical { align-items: flex-start; }

    .is-center-sm-down:not(.is-vertical) {
        justify-content: center;
        text-align: center;
        object-position: center;
    }
    .is-center-sm-down.is-vertical { align-items: center; }

    .is-right-sm-down:not(.is-vertical) {
        justify-content: flex-end;
        text-align: right;
        object-position: right;
    }
    .is-right-sm-down.is-vertical { align-items: flex-end; }
}

[class*="o-flex"].is-bottom { align-items: flex-end; }

.is-top {
    align-self: flex-start;
    object-position: top;
}

.is-bottom {
    align-self: flex-end;
    object-position: bottom;
}

@media (max-width: 767px) {
    .is-left-sm-down:not(.is-vertical) {
        justify-content: flex-start;
        text-align: left;
        object-position: left;
    }
    .is-right-sm-down:not(.is-vertical) {
        justify-content: flex-end;
        text-align: right;
        object-position: right;
    }
    .is-center-sm-down:not(.is-vertical) {
        justify-content: center;
        text-align: center;
        object-position: center;
    }
}

/* --- Responsive --- */

@media (max-width: 543px) { .hidden-xs { display: none; } }
@media (min-width: 544px) and (max-width: 767px) { .hidden-sm { display: none; } }
@media (min-width: 768px) and (max-width: 991px) { .hidden-md { display: none; } }
@media (min-width: 992px) and (max-width: 1199px) { .hidden-lg { display: none; } }
@media (min-width: 1200px) { .hidden-xl { display: none; } }
@media (min-width: 544px) { .hidden-sm-up { display: none; } }
@media (min-width: 768px) { .hidden-md-up { display: none; } }
@media (min-width: 992px) { .hidden-lg-up { display: none; } }
@media (max-width: 767px) { .hidden-sm-down { display: none; } }
@media (max-width: 991px) { .hidden-md-down { display: none; } }
@media (max-width: 1199px) { .hidden-lg-down { display: none; } }
@media (max-width: 992px) { .hidden-burger-nav { display: none; } }
@media (min-width: 993px) { .hidden-standard-nav { display: none; } }

.hidden {
    display: none;
}

.show {
    display: initial;
}

/* --- Text --- */

.noh {
    font-size: inherit;
    font-family: var(--default-font-family);
    color: inherit;
    font-weight: inherit;
    line-height: inherit;
    margin: auto;
}

p {
    font-feature-settings: 'liga' 1, 'lnum' 1, 'kern' 1;
    text-rendering: geometricPrecision;
}

p:not(:last-child) { margin-bottom: var(--p-margin-bottom); }

strong, .-strong { font-weight: 700; }
em { font-style: italic; }

.has-thin-font {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: initial;
}

.has-thin-font strong {
    -moz-osx-font-smoothing: grayscale;
}

.has-columns {
    word-wrap: normal;
    overflow-wrap: break-word;
    hyphens: auto;
    columns: 25em auto;
    column-gap: var(--layout-gutter);
    orphans: 3;
    widows: 2;
}

@supports not ((-webkit-hyphens: auto) or (-ms-hyphens: auto) or (hyphens: auto)) {
    .has-columns  {
        hyphens: manual;
        word-break: break-word;
    }
}

.is-not-selectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

sup, sub { font-size: smaller; }
sup { vertical-align: super; }
sub { vertical-align: sub; }


/* --- Images --- */

.svg-symbols {
    position: absolute;
    width: 0;
    height: 0;
}

.svg-icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    fill: currentColor;
    vertical-align: middle;
}

.has-svg-icon {
    line-height: 0;
    display: block;
    overflow: visible; /* fix overflow cut */
}

.has-svg-icon > svg {
    vertical-align: middle;
    position: relative;
    bottom: 1px;
}

.has-svg-icon > img {
    display: block;
}

/* --- Scroll --- */

/* Block scroll on Desktop */
html.is-not-scrollable,
html.is-not-scrollable > body,
.is-not-scrollable {
    overflow: hidden !important;
    /* height: 100% !important; */
}

.is-scrollable {
    overflow: hidden !important;
    overflow-y: auto !important;
    -webkit-overflow-scrolling: touch;
}

/* block scroll effect on iOS but force to scroll on top */
.o-view.is-not-scrollable {
    position: fixed;
}

.-relative {
    position: relative;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

article {
    display: inline-block;
    width: 100%;
}

.mgnlEditorBar, .mgnlEditor {
    grid-column: span 2 / span 2;
}
