main:empty {
    margin-bottom: var(--section-spacing);
}

/* Top margins */
.mt-component:not(:first-child) {
    margin-top: var(--component-spacing);
}

.mt-component-2:not(:first-child) {
    margin-top: var(--component-spacing-2);
}

.mt-header:not(:first-child) {
    margin-top: var(--header-spacing);
}

.mt-block:not(:first-child) {
    margin-top: var(--block-spacing);
}

.mt-element:not(:first-child) {
    margin-top: var(--element-spacing);
}

.mt-quote:not(:first-child) {
    margin-top: var(--quote-spacing);
}

.mt-text:not(:first-child) {
    margin-top: var(--text-spacing);
}

.mt-tiny:not(:first-child) {
    margin-top: var(--tiny-spacing);
}

.mt-small {
    margin-top: var(--small-spacing);
}

.mt-article {
    margin-top: var(--article-spacing);
}

.mt-section {
    margin-top: var(--section-spacing);
}

.pt-micro {
    padding-top: 5px;
}

/* Bottom margins */
.mb-section {
    margin-bottom: var(--section-spacing);
}

.mb-component:not(:last-child) {
    margin-bottom: var(--component-spacing);
}

.mb-component-2:not(:last-child) {
    margin-bottom: var(--component-spacing-2);
}

.mb-header:not(:last-child) {
    margin-bottom: var(--header-spacing);
}

.mb-block:not(:last-child) {
    margin-bottom: var(--block-spacing);
}

.mb-article:not(:last-child) {
    margin-bottom: var(--article-spacing);
}

.mb-element:not(:last-child) {
    margin-bottom: var(--element-spacing);
}

.mb-quote:not(:last-child) {
    margin-bottom: var(--quote-spacing);
}

.mb-big-text:not(:last-child) {
    margin-bottom: var(--big-text-spacing);
}

.pb-big-text {
    padding-bottom: var(--big-text-spacing);
}

.mb-text:not(:last-child) {
    margin-bottom: var(--text-spacing);
}

.pb-text {
    padding-bottom: var(--text-spacing);
}

.mb-tiny:not(:last-child) {
    margin-bottom: var(--tiny-spacing);
}

.mb-small {
    margin-bottom: var(--small-spacing);
}

.mb-micro {
    margin-bottom: 5px;
}

@media(max-width: 768px) {
    /* Top margins */
    .mt-component-sm-down:not(:first-child) {
        margin-top: var(--component-spacing);
    }

    .mt-component-2-sm-down:not(:first-child) {
        margin-top: var(--component-spacing-2);
    }

    .mt-header-sm-down:not(:first-child) {
        margin-top: var(--header-spacing);
    }

    .mt-block-sm-down:not(:first-child) {
        margin-top: var(--block-spacing);
    }

    .mt-element-sm-down:not(:first-child) {
        margin-top: var(--element-spacing);
    }

    .mt-quote-sm-down:not(:first-child) {
        margin-top: var(--quote-spacing);
    }

    .mt-text-sm-down:not(:first-child) {
        margin-top: var(--text-spacing);
    }

    .mt-tiny-sm-down:not(:first-child) {
        margin-top: var(--tiny-spacing);
    }

    .mt-small-sm-down {
        margin-top: var(--small-spacing);
    }

    .mt-article-sm-down {
        margin-top: var(--article-spacing);
    }

    .mt-section-sm-down {
        margin-top: var(--section-spacing);
    }

    .mt-none-sm-down {
        margin-top: 0;
    }

    .mt-micro-sm-down {
        margin-top: 5px;
    }

    /* Bottom margins */
    .mb-section-sm-down {
        margin-bottom: var(--section-spacing);
    }

    .mb-component-sm-down:not(:last-child) {
        margin-bottom: var(--component-spacing);
    }

    .mb-component-2-sm-down:not(:last-child) {
        margin-bottom: var(--component-spacing-2);
    }

    .mb-header-sm-down:not(:last-child) {
        margin-bottom: var(--header-spacing);
    }

    .mb-block-sm-down:not(:last-child) {
        margin-bottom: var(--block-spacing);
    }

    .mb-element-sm-down:not(:last-child) {
        margin-bottom: var(--element-spacing);
    }

    .mb-quote-sm-down:not(:last-child) {
        margin-bottom: var(--quote-spacing);
    }

    .mb-text-sm-down:not(:last-child) {
        margin-bottom: var(--text-spacing);
    }

    .pb-text-sm-down {
        padding-bottom: var(--text-spacing);
    }

    .mb-tiny-sm-down:not(:last-child) {
        margin-bottom: var(--tiny-spacing);
    }

    .mb-small-sm-down {
        margin-bottom: var(--small-spacing);
    }

    .mb-none-sm-down:not(:last-child) {
        margin-bottom: 0;
    }

    .mb-micro-sm-down {
        margin-bottom: 5px;
    }
}

.bg-white {
    background-color: var(--weiss);
}

.bg-iceBlue {
    background-color: var(--grau4);
}

.bg-lightBlue {
    background-color: var(--hellblau);
}

.bg-offWhite {
    background-color: var(--off-white);
}

.bg-lightGrey {
    background-color: var(--hellgrau);
}

.bg-lightYellow {
    background-color: var(--hellgelb);
}

.bg-lightGreen {
    background-color: var(--hellgruen);
}

.bg-lightRed {
    background-color: var(--hellrot);
}

.box {
    padding: 24px;
}

.box-margins-fix {
     margin: -24px 0;
}

@media(min-width: 768px) {
    .box {
        padding: 32px;
    }

    .box-margins-fix {
        margin: -32px 0;
    }
}

@media(max-width: 768px) {
    .box-full-width {
        padding: 24px 0;
    }
}

@media(min-width: 768px) {
    .absolute-md-up {
        position: absolute;
    }
}

figcaption:not(:empty) {
    margin-top: 10px;
}

figcaption.cta-bounded:not(:empty) {
    position: absolute;
    width: 60%;
    max-width: calc(100vw - 220px);
    line-height: 1.25em;
}

@media (max-width: 768px) {
    figcaption.cta-bounded {
        display: none;
    }
}


.bsb-component-grid {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 120px;
}

.bsb-block-grid {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 40px;
}

.bsb-job-grid {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
}

@media(min-width: 922px) {
    .bsb-job-grid {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 16px;
        grid-row-gap: 16px;
    }

    .bsb-block-grid {
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 67px;
    }
}

.bsb-component {
    grid-column: span 2 / span 2;
}

@media(min-width: 768px) {
    .bsb-component-half {
        grid-column: span 1 / span 1;
    }
}

.col-start-1 {
    grid-column-start: 1;
}

