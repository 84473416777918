/* Fadein */
@keyframes fadein {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.js-fadein {
    opacity: 0;
}

.js-animate.js-fadein {
    animation: fadein 0.5s ease-out 0s 1 normal forwards;
}

.js-animate-instantly.js-fadein {
    animation: fadein 0.5s ease-out 0s 1 normal forwards;
}

/* Fadein Children */
.js-fadein-children > * {
    opacity: 0;
}

.js-animate-instantly.js-fadein-children > * {
    animation: fadein 0.5s ease-out 0s 1 normal forwards;
}

.js-animate.js-fadein-children > * {
    animation: fadein 1s ease-out 0s 1 normal forwards;
    animation-delay: 0.3s;
}

.js-animate.js-fadein-children > *:nth-child(3) {
    animation-delay: 0.2s;
}

.js-animate.js-fadein-children > *:nth-child(2) {
    animation-delay: 0.1s;
}

.js-animate.js-fadein-children > *:nth-child(1) {
    animation-delay: 0s;
}

/* SlideUp */
@keyframes slideup {
    0% { opacity: 0; transform: translateY(60px); }
    50% { opacity: 0.5; transform: translateY(30px); }
    50% { opacity: 0.75; transform: translateY(0); }
    100% { opacity: 1; transform: translateY(0); }
}

.js-slideup {
    opacity: 0;
    transform: translateY(60px);
}

.js-animate.js-slideup {
    animation: slideup 1s ease-out 0s 1 normal forwards;
}

.js-animate-instantly.js-slideup {
    animation: fadein 0.25s ease-out 0s 1 normal forwards;
}

/* Slideup Children */
.js-slideup-children > * {
    opacity: 0;
    transform: translateY(60px);
}

.js-animate-instantly.js-slideup-children > * {
    animation: slideup 0.5s ease-out 0s 1 normal forwards;
    animation-delay: 0.3s;
}

.js-animate.js-slideup-children > * {
    animation: slideup 1s ease-out 0s 1 normal forwards;
    animation-delay: 0.3s;
}

.js-animate-instantly.js-slideup-children > *:nth-child(5),
.js-animate.js-slideup-children > *:nth-child(5) {
    animation-delay: 0.275s;
}

.js-animate-instantly.js-slideup-children > *:nth-child(4),
.js-animate.js-slideup-children > *:nth-child(4) {
    animation-delay: 0.25s;
}

.js-animate-instantly.js-slideup-children > *:nth-child(3),
.js-animate.js-slideup-children > *:nth-child(3) {
    animation-delay: 0.2s;
}

.js-animate-instantly.js-slideup-children > *:nth-child(2),
.js-animate.js-slideup-children > *:nth-child(2) {
    animation-delay: 0.1s;
}

.js-animate-instantly.js-slideup-children > *:nth-child(1),
.js-animate.js-slideup-children > *:nth-child(1) {
    animation-delay: 0s;
}
